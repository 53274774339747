import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Video Script",
  desc: "Buat engagement video script dengan mudah untuk Instagram, TikTok, Youtube, dan lainnya.",
  category: "video",
  Icon: ViewListIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "gray-500",
  toColor: "gray-500",

  to: "/ai/video/vodeo_script",
  api: "/ai/video/vodeo_script",

  output: {
    title: "Video Script",
    desc: "Video Script",
    Icon: false,
    color: "blue",
  },

  prompts: [
    {
      title: "Entry Text",
      desc: "A sentence or paragraph you wish to understand in bullet point form.",
      // n: 1,
      // Judul : Mengapa kamu harus belajar Docker
      // Bahsa : indonesia
      // Gaya Bahasa :  Akrab
      // jangka waktu : 5 menit
      prompts: [
        {
          title: "Judul",
          attr: "Judul",
          value: "",
          placeholder: "Mengapa kita harus belajar Data Science?",
          label: "",
          type: "text",
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "Hello World ",
        },
        {
          title: "Bahasa",
          attr: "Bahasa",
          value: "",
          placeholder: "Bahasa apa yang akan kamu pakai",
          label: "",
          type: "text",
          maxLength: 40,
          error: "",
          required: true,
          example: "Indonesia",
        },
        {
          title: "Gaya Bahasa",
          attr: "Gaya Bahasa",
          value: "",
          placeholder: "Formal,Friendly,Informal",
          label: "",
          type: "text",
          maxLength: 40,
          error: "",
          required: true,
          example: "Formal",
        },
        {
          title: "Durasi",
          attr: "Durasi",
          value: "",
          placeholder: "Durasi video",
          label: "",
          type: "text",
          maxLength: 40,
          error: "",
          required: true,
          example: "5 menit",
        },
      ],
      example: {
        output:
          "Hello World Hello World Hello World Hello World Hello World Hello World Hello World ",
        // outputs: [
        // 	"The sun is very old, over 4.5 billion years",
        // 	"At 10,000 degrees, sun is also very hot",
        // 	"Gasses called coronal mass ejections erupt from the sun",
        // ],
        // Icon: RefreshIcon,
        color: "blue",
      },
    },
  ],
};

export default obj;
