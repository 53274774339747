import { ClipboardListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Iklan Pekerjaan",
  desc: "Buat iklan pekerjaan dengan cepat berdasarkan beberapa detail, seperti judul, persyaratan, dll.",
  category: "Business",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/business/jobad",
  api: "/ai/business/jobad",

  fromColor: "blue-600",
  toColor: "yellow-500",

  output: {
    title: "Contoh Iklan Pekerjaan",
    desc: "Contoh iklan pekerjaan yang dihasilkan oleh AI, silahkan edit iklan pekerjaan ini sesuai dengan kebutuhan anda.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Masukkan detail iklan pekerjaan",
      desc: "Tulis detail pendek iklan pekerjaan yang akan digunakan untuk membuat iklan pekerjaan.",
      // n: 1,
      prompts: [
        {
          title: "Judul",
          attr: "Judul",
          value: "",
          placeholder: "Junior Accountant",
          label: "Contoh: Accountant, Developer, Dentist, Receptionist",
          // type: "textarea",
          maxLength: 40,
          // max: 100,
          min: 10,
          required: true,
          error: "",
          example: "junior accountant",
        },
        {
          title: "Salary / Gaji ",
          attr: "salary",
          value: "",
          placeholder: "RP 10.000.000",
          label: "Contoh: 10 Juta, 20 Juta, 30 Juta, 40 Juta",
          // type: "textarea",
          maxLength: 20,
          // max: 100,
          min: 3,
          required: true,
          error: "",
          example: "10 Juta",
        },
        {
          title: "Skills dan Kualifikasi",
          attr: "skills",
          value: "",
          placeholder: "Audit Pajak, Reports, Desain Logos, dll",
          label: "Examples: Microsoft CRM, Adobe, Till, MYOB, Xero, Quickbooks",
          // type: "textarea",
          // maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "Audit Pajak, Reports, MyOB",
        },
        {
          title: "Perusahaan",
          attr: "company",
          value: "",
          placeholder: "PT.JeniAI",
          label: "Contoh: PT.JeniAI",
          // type: "textarea",
          maxLength: 40,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "PT.JeniAI",
        },
        {
          title: "Kontak",
          attr: "contact",
          value: "",
          placeholder: "Diki diki@jeni.ai",
          label: "Contoh: Diki diki@jeni.ai atau 08123456789",
          // type: "textarea",
          // maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "Diki M S diki@jeni.ai",
        },
        {
          title: "Bahasa",
          attr: "language",
          label: "Contoh: en, id",
          type: "text",
          options: [
            { value: "en", label: "English" },
            { value: "id", label: "Indonesia" },
          ],
        },
      ],
      example: {
        output: `Smith & Co is looking for a Junior Accountant to join our team. If you have experience in accounting and bookkeeping, you will get the chance to work on a wide range of financial and accounting tasks.

The Role:
- Work on a variety of financial tasks
- Do bank reconciliations
- Prepare reports and financial statements
- Work with the CFO to ensure the company is compliant with the financial regulations
			
Skills and Experience:
- Proven experience in an accounting role
- Experience in MYOB would be an advantage
- Experience in a retail environment would be an advantage
			
How to apply:
If you are interested, you can apply by contacting us directly at Smith & Co or email john@smith.com.au.`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
    {
      title: "Basic Ad",
      desc: "Write a short few words about the ad",
      // n: 1,
      prompts: [
        {
          title: "Job Information",
          attr: "content",
          value: "",
          placeholder:
            "accountant 40k can do report, audit, myob, Smith and Co, contact Ryan ryan@co.com",
          label: "",
          type: "textarea",
          maxLength: 400,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example:
            "Junior Accountant earning Rp. 10.000.000 that does reports, audits, uses myob to work for Smith and Co contact Diki at diki@jeni.ai",
        },
      ],
      example: {
        output: `Smith & Co is looking for a Junior Accountant to join our team. If you have experience in accounting and bookkeeping, you will get the chance to work on a wide range of financial and accounting tasks.

The Role:
- Work on a variety of financial tasks
- Do bank reconciliations
- Prepare reports and financial statements
- Work with the CFO to ensure the company is compliant with the financial regulations
			
Skills and Experience:
- Proven experience in an accounting role
- Experience in MYOB would be an advantage
- Experience in a retail environment would be an advantage
			
How to apply:
If you are interested, you can apply by contacting us directly at Smith & Co or email john@smith.com.au.`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
