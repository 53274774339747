import { MenuAlt2Icon } from "@heroicons/react/solid";

const obj = {
  title: "Paragraf Intro",
  // desc in indonesia
  desc: "Buat Paragraf intro untuk artikel anda.",
  category: "Content",
  Icon: MenuAlt2Icon,
  // tags: [],
  permissions: ["user"],

  fromColor: "yellow-400",
  toColor: "yellow-600",

  to: "/ai/writing/intro",
  api: "/ai/writing/intro",

  output: {
    title: "Intro",
    desc: "Kalimat ini di generate oleh AI, silahkan edit kalimat ini sesuai dengan kebutuhan anda.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Masukkan clue atau kata kunci",
      desc: "Tulis kata kunci atau clue yang akan digunakan untuk membuat kalimat pembuka atau paragraf.",
      // n: 1,
      prompts: [
        {
          title: "Judul",
          attr: "title",
          value: "",
          placeholder: "Judul",
          label: "Judul artikel anda",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          min: 5,
          required: true,
          error: "",
          example: "Mengapa menggunakan storybook untuk dokumentasi",
        },
        {
          title: "Audience",
          attr: "audience",
          value: "",
          placeholder: "Target Audience",
          label: "Target audience untuk artikel anda",
          // type: "textarea",
          maxLength: 50,
          // max: 100,
          // min: 5,
          // required: true,
          error: "",
          example: "Teknologi dan developement",
        },
        {
          title: "Deskripsi",
          attr: "desc",
          value: "",
          placeholder:
            "Artikel tentang bagaimana caranya coding menggunakan python",
          label: "Deskripsi singkat tentang artikel anda",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 100,
          // required: true,
          error: "",
          example:
            "Artikel tentang mengapa penting menggunakan story book untuk mendokumentasikan kemajuan Anda bahkan saat bekerja tanpa kerangka kerja javascript untuk membantu Anda memahami apa yang Anda lakukan.",
        },
        {
          title: "Keywords",
          attr: "keywords",
          value: "",
          placeholder: "Tech, code, etc...",
          label:
            "Keywords atau kata kunci yang akan digunakan untuk artikel anda",
          // type: "textarea",
          maxLength: 100,
          // max: 100,
          // min: 4,
          // required: true,
          error: "",
          example: "Coding, development, html, css, js",
        },
      ],
      example: {
        output:
          "Storybook adalah lingkungan pengembangan untuk komponen UI. Ini membantu Anda mengulang lebih cepat pada kode UI. Ini memungkinkan Anda menjelajahi pustaka komponen, melihat status yang berbeda dari setiap komponen, serta mengembangkan dan menguji komponen secara interaktif.\n\nSaya yakin dengan nilai Buku Cerita, dan saya yakin ini akan meningkatkan alur kerja Anda. Tapi saya juga akan berbagi dengan Anda pendekatan berbeda yang dapat membantu Anda menghemat waktu tanpa menggunakan framework JavaScript.",
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
