import {
	ChevronRightIcon,
} from '@heroicons/react/solid'

import {
	EyeIcon,
} from '@heroicons/react/outline'



const obj = {

	title: "Explain Code",
	desc: "Code Intrepreter Untuk mejelaskan cara kerja suatu code",
	category: "Programming",
	Icon: EyeIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-800",
	toColor: "gray-600",

	to: "/ai/code/interpret",
	api: "/ai/code/interpret",

	output: {
		title: "Penjelasan",
		desc: "",
		Icon: ChevronRightIcon,
		color: "gray",
	},

	prompts: [{
		title:"Interpret Code",
		desc: "Tulis codingannya di bawah",
		// n: 1,
		prompts: [
			{ 
				title: "Bahasa Pemogramannya", 
				attr: "language",  
				value: "", 
				placeholder: "JavaScript...", 
				label: "Bahasa pemograman apa yang akan kamu pakai",
				type: "text",
				maxLength: 40,
				// options: [{ title: "2nd Grader", value: "2nd Grader", desc: "Explain this like I'm 5 years old", Icon: AnnotationIcon },],
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `JavaScript`,
			},
			{ 
				title: "Code", 
				attr: "content",  
				value: "", 
				placeholder: "function Name(attr){...", 
				label: "Maasukan codingannya, Kemudian akan ku jelaskan ",
				type: "code",
				maxLength: 2000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `function HelloWorld(text){ 
	let text || "Hello World"; 
	console.log(text);
}`,
			},
		],
		example: {
			output: ``,
			outputs: [
				
				"Ini adalah sebuah fungsi yang memiliki satu argumen, namun tidak memiliki nilai default.",
				"Fungsi ini akan mengambil satu argumen dari yang diperlukan, dan mengembalikan nilai dari argumen tersebut.",
				"Fungsi ini akan mengambil satu argumen dari yang diperlukan, dan mengembalikan nilai dari argumen tersebut."
			],
			// Icon: TerminalIcon,
			// color: "gray",
		}
	}]
		
}

export default obj

